function validateFieldLength(field, model, next, maxLength) {
  return model[field.key] ? next(model[field.key].length <= maxLength) : next(false);
}

const tokenFields = [
  {
    key: 'merchantId',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Merchant ID',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Merchant ID',
        },
      },
    },
    validators: {
      length: {
        expression: (field, model, next) => validateFieldLength(field, model, next, 50),
        message: 'Merchant ID must not exceed 50 characters',
      },
    },
  },
  {
    key: 'interfaceStatus',
    type: 'select-with-field',
    required: true,
    templateOptions: {
      options: [
        {
          text: 'Sandbox',
          value: 'SANDBOX',
        },
        {
          text: 'Live',
          value: 'LIVE',
        },
      ],
      properties: {
        placeholder: 'Select an interface status',
      },
      wrapper: {
        properties: {
          addons: false,
          label: 'Interface Status',
        },
      },
    },
  },
  {
    key: 'beneficiaryName',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Beneficiary Name',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Beneficiary Name',
        },
      },
    },
    validators: {
      length: {
        expression: (field, model, next) => validateFieldLength(field, model, next, 100),
        message: 'Beneficiary Name must not exceed 100 characters.',
      },
    },
  },
  {
    key: 'accountNumber',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Account Number',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Account Number',
        },
      },
    },
    validators: {
      combined: {
        expression: (field, model, next) => {
          const value = model[field.key];
          const isValidLength = value?.length === 8;
          const isNumeric = /^\d+$/.test(value);
          next(isValidLength && isNumeric);
        },
        message: 'Account Number must be exactly 8 characters and contain only numbers',
      },
    },
  },
  {
    key: 'sortCode',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Sort Code',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Sort Code',
        },
      },
    },
    validators: {
      combined: {
        expression: (field, model, next) => {
          const value = model[field.key];
          const isValidLength = value?.length === 6;
          const isNumeric = /^\d+$/.test(value);
          next(isValidLength && isNumeric);
        },
        message: 'Sort Code must be exactly 6 characters and contain only numbers',
      },
    },
  },
];

export default tokenFields;
